import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/cookiebeleid/"> Cookiebeleid</a>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-131">
            <h1>Cookiebeleid</h1>
            <p>
              Dit Cookiebeleid is bedoeld om je te informeren over
              hoe wij informatie verzamelen door middel van cookies
              en andere traceringstechnologieën (zoals gifs, web beacons, enz.).
            </p>
            <p>
              We kunnen deze technologieën voor onder andere volgende doeleinden gebruiken:
            </p>
            <ul>
              <li>Je helpen bij het surfen op onze pagina’s,</li>
              <li>
                Je helpen bij het registreren voor onze events, inloggen, en feedback geven,
              </li>
              <li>
                Het analyseren van het gebruik van onze producten, diensten of applicaties,
              </li>
              <li>
                Ons helpen bij onze reclame- en marketingprojecten (waaronder gedragsgestuurd adverteren),
              </li>
              <li>
                Het aanbieden van inhoud van derden (zoals social media inhoud).
              </li>
            </ul>
            <p>
              Hieronder vind je een lijst met de cookies die we gebruiken, met een beschrijving.
              We verdelen cookies in de volgende categorieën:
            </p>
            <ul>
              <li>Strikt noodzakelijke cookies</li>
              <li>
                Prestatiecookies
              </li>
              <li>
                Functionele cookies
              </li>
              <li>
                Doelgroepgerichte cookies </li>
            </ul>
            <p>
              Tenzij anders toegelaten door de toepasselijke wetgeving, plaatsen we cookies nadat we jouw
              toestemming gekregen hebben via de cookiebanner of het voorkeurenmenu. Je kan jouw cookie-instellingen steeds wijzigen per categorie (behalve voor strikt noodzakelijke cookie die nodig zijn
              om de site juist te laten werken) door hieronder op de “cookie-instellingen” knop te klikken:
            </p>
            <div><button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie-instellingen</button></div>
            <br />
            <p>&nbsp;</p>
            <div id="ot-sdk-cookie-policy"></div>
            <h2>Koppelingen naar andere websites</h2>
            <p>
              Deze site kan koppelingen of verwijzingen naar andere websites bevatten. Denk eraan dat wij de Cookies/traceringstechnologieën van andere websites niet beheren,
              en dat dit Cookiebeleid niet van toepassing is op die websites.
            </p>
            <h2>Hoe neemt u contact met ons op</h2>
            <p>
              Als u vragen, opmerkingen, verzoeken of bezorgdheden hebt met betrekking tot dit Cookiebeleid of de manier van informatieverwerking op deze site,
              gebruik a.u.b. de contactinformatie die opgenomen is in onze Privacybeleid.
            </p>
            <h2>Wijzigingen aan dit Cookiebeleid</h2>
            <p>
              Als dit Cookiebeleid wordt gewijzigd, wordt het herziene beleid hier gepubliceerd.
             De laatste herziening van dit Cookiebeleid was op 12.5.2021.</p>

          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
